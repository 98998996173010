import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import ImagesModule from "../components/images"
import testimonials from "../../locales/fr/references.json"
import DualSplit from "../components/sections/dual-split"
import SectionCTA from "../components/sections/section-cta"
import useModal from "../components/use-modal"
import { Trans, useTranslation } from "gatsby-plugin-react-i18next"
import Seo from "../components/seo"

const ReferencesClientsPage = () => {

    const { Logo } = ImagesModule()
    const [toggle, ContactModal] = useModal()
    const STEP = 10
    const [numberOfReferencesToShow, setNumberOfReferencesToShow] = React.useState(STEP)

    const messages = testimonials.references

    const { t } = useTranslation(['page_references'])
    const tRef = t('references')
    const tLastCta = t('last_cta')
    const tMeta = t('meta')

    return (
        <Layout>
            <Seo
                title={tMeta.title}
                description={tMeta.description}
            />
            <ContactModal />
            <section className="section">
                <div className="container">
                    <h2 className="title is-3 is-uppercase mb-6 has-text-centered">
                        {tRef.title}
                    </h2>
                    {
                        messages.map(
                            (message, index) => (
                                <article
                                    className={["media", (index >= numberOfReferencesToShow) ? "is-hidden" : undefined].join(" ")}
                                    key={message.logo + index}
                                >
                                    <figure className="media-left">

                                        <div className="image is-64x64">
                                            <Logo name={message.logo} className="is-block" />
                                        </div>
                                    </figure>
                                    <div className="media-content">
                                        <div className="content">
                                            <p>
                                                <strong>{message.name}</strong> <small>{message.businessname}</small> <small>({message.businesslocalization})</small>
                                                <br />
                                                {message.description}
                                            </p>
                                        </div>
                                    </div>
                                </article>
                            )
                        )
                    }
                    {
                        (numberOfReferencesToShow <= messages.length) &&
                        <p className="mt-5 has-text-centered">
                            <button
                                className="button is-primary is-outlined has-text-weight-bold is-medium is-uppercase"
                                onClick={
                                    () => {
                                        if (numberOfReferencesToShow + STEP >= messages.length) {
                                            setNumberOfReferencesToShow(messages.length)
                                        } else setNumberOfReferencesToShow(numberOfReferencesToShow + STEP)
                                    }
                                }
                            >
                                <span>
                                    {tRef.more}
                                </span>
                                <span className="icon">
                                    ↓
                                    </span>
                            </button>
                        </p>
                    }
                </div>
            </section>

            <DualSplit />
            <SectionCTA
                title={tLastCta.title}
                picture="together-palms"
                toggle={toggle}
                imageAlt={tLastCta.image_alt}
            >
                <p className="mb-5">
                    <Trans
                        i18nKey={tLastCta.p1}
                        components={{ b: <b /> }}
                    />
                </p>
                <p>{tLastCta.p2}</p>
            </SectionCTA>

        </Layout>
    )
}
export default ReferencesClientsPage


export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;