import React from "react"
import CTA from "../elements/cta-button"
import ImagesModule from "../images"
import { useTranslation } from "gatsby-plugin-react-i18next"

export default ({ title, picture, children, toggle, imageAlt }) => {

    const { Picto, Picture } = ImagesModule()

    const { t } = useTranslation('sections')

    return (
        <section className="section">
            <div className="columns is-multiline">
                <div className="column is-3">
                    <Picto
                        name="devis-gratuit"
                        style={{
                            maxWidth: "150px",
                            margin: "0 auto",
                            filter: "invert() contrast(50%)",
                        }}
                        className="mb-5 is-hidden-mobile"
                    />
                </div>
                <div className="column is-6 box px-6 py-6">
                    <h2 className="title is-3">
                        {title}
                    </h2>
                    {children}
                    <Picto
                        name="devis-gratuit"
                        style={{
                            maxWidth: "150px",
                            margin: "0 auto",
                            filter: "invert() contrast(50%)",
                        }}
                        className="mt-5 is-hidden-tablet"
                    />
                    <p className="has-text-centered">
                        <CTA className="mt-5" onClick={toggle}>
                            {t('section_cta').cta}
                        </CTA>
                    </p>
                </div>
                <div
                    className="column is-10 is-offset-1 is-relative is-hidden-mobile"
                    style={{ zIndex: -1, marginTop: "-10em" }}
                >
                    <Picture name={picture} alt={imageAlt} />
                </div>
            </div>
        </section>
    )
}